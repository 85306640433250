import { Path } from "react-router-dom";
import { AppRouteObject } from "../../routes";
import { generateEncodedPath } from "../../utils/generateEncodedPath";

import Ldap from "../pages/LdapPages/Ldap";
import CreateLdap from "../pages/LdapPages/CreateLdap";
import EditLdap from "../pages/LdapPages/EditLdap";

const LdapRoute: AppRouteObject = {
  path: "/:realm/ldap",
  element: <Ldap />,
  breadcrumb: (t) => t("point-ldapConnections"),
  handle: {
    access: ["anyone"],
  },
};

export const toLdapRoute = (params = {}): Partial<Path> => ({
  pathname: generateEncodedPath(LdapRoute.path, params),
});

const CreateLdapRoute: AppRouteObject = {
  path: "/:realm/ldap/create",
  element: <CreateLdap />,
  breadcrumb: (t) => t("point-ldapConnections"),
  handle: {
    access: ["anyone"],
  },
};

const EditLdapRoute: AppRouteObject = {
  path: "/:realm/ldap/:ldapId",
  element: <EditLdap />,
  breadcrumb: (t) => t("point-ldapConnections"),
  handle: {
    access: ["anyone"],
  },
};

export const ldapRoutes = [LdapRoute, CreateLdapRoute, EditLdapRoute];
