import KeycloakAdminClient from "@keycloak/keycloak-admin-client";
import { adminClient } from "../../admin-client";
import { getAuthorizationHeaders } from "../../utils/getAuthorizationHeaders";
import { CreateLdapSchema } from "../pages/LdapPages/CreateLdap";

class LdapService extends KeycloakAdminClient {
  constructor() {
    super();
  }

  async pointLDAPConnection({
    ldapBindPassword,
    ldapBindUsername,
    ldapHost,
    ldapPort,
  }: Omit<
    CreateLdapSchema,
    "id" | "name" | "ldapDomainName" | "ldapOrganizationalUnit"
  >) {
    let data = false;
    try {
      const response = await fetch(
        `${adminClient.baseUrl}/realms/${adminClient.realmName}/point-api/testLDAPConnection`,
        {
          signal: AbortSignal.timeout(5000),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getAuthorizationHeaders(await adminClient.getAccessToken()),
          },
          body: JSON.stringify({
            ldapBindPassword,
            ldapBindUsername,
            ldapHost,
            ldapPort,
          }),
        },
      );
      data = response.ok;
    } catch (error) {
      data = false;
    }

    return data;
  }
}

export default new LdapService();
