import { useCallback, useEffect, useState } from "react";
import { CreateTheme } from "../CreateEmailTheme";
import { useTranslation } from "react-i18next";
import { useAlerts } from "../../../../components/alert/Alerts";
import { adminClient } from "../../../../admin-client";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { AlertVariant } from "@patternfly/react-core";
import { useRealm } from "../../../../context/realm-context/RealmContext";

export default function useEmailTheme() {
  const [tableData, setTableData] = useState<
    (CreateTheme & { uuid: string })[]
  >([]);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const { realm } = useRealm();
  const { t } = useTranslation("point");
  const { addAlert, addError } = useAlerts();

  const getEmailThemes = useCallback(async () => {
    try {
      const response = await fetch(
        `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/allThemes`,
        {
          method: "GET",
          headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      );
      const themeJsonsData = await response.json();
      if (themeJsonsData && Array.isArray(themeJsonsData)) {
        setTableData(themeJsonsData);
      }
    } catch (error) {
      addError(t("point-error"), error);
      setTableData([]);
    }
  }, [realm]);

  const handleDeleteTheme = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await fetch(
        `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/theme/${id}`,
        {
          method: "DELETE",
          headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
        },
      );
      if (response.ok) {
        addAlert("Success ! Template Deleted", AlertVariant.success);
      }
    } catch (error) {
      addError(t("point-error"), error);
    } finally {
      getEmailThemes();
    }
  };

  const handleAreYouSureDelete = (id: string) => {
    setDeleteId(id);
  };

  useEffect(() => {
    getEmailThemes();
  }, [getEmailThemes]);

  return { deleteId, handleAreYouSureDelete, tableData, handleDeleteTheme };
}
