import { useTranslation } from "react-i18next";
import { ViewHeader } from "../../../../components/view-header/ViewHeader";

import {
  Button,
  ButtonVariant,
  PageSection,
  ToolbarItem,
} from "@patternfly/react-core";
import {
  KeycloakDataTable,
  Action,
} from "../../../../components/table-toolbar/KeycloakDataTable";
import { IRowData, TableText, cellWidth } from "@patternfly/react-table";
import { emptyFormatter } from "../../../../util";
import type ClientRepresentation from "@keycloak/keycloak-admin-client/lib/defs/clientRepresentation";

import { Link, useNavigate } from "react-router-dom";

import useIntegrations from "./useIntegrations";

import { useConfirmDialog } from "../../../../components/confirm-dialog/ConfirmDialog";

const ToolbarItems = () => {
  const { t } = useTranslation();

  return (
    <ToolbarItem>
      <Button component={(props) => <Link {...props} to={"create-webhook"} />}>
        {t("point-createWebhook")}
      </Button>
    </ToolbarItem>
  );
};

const TextCell = (value: string) => {
  return (
    <TableText wrapModifier="truncate">
      {emptyFormatter()(value) as string}
    </TableText>
  );
};

export default function Integrations() {
  const { deleteId, tableData, handleDeleteWebhook, handleAreYouSureDelete } =
    useIntegrations();

  const [toggleDeleteDialog, DeleteConfirm] = useConfirmDialog({
    titleKey: "Are you sure?",
    messageKey: "After delete there is no way to retrieve value",
    continueButtonLabel: "delete",
    continueButtonVariant: ButtonVariant.danger,
    onConfirm: () => handleDeleteWebhook(deleteId),
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ViewHeader titleKey={t("point-integrations")} divider />

      <PageSection variant="light" className="pf-u-mr-sm">
        <ToolbarItems />
        <DeleteConfirm />
        <KeycloakDataTable
          loader={tableData}
          ariaLabelKey="integration"
          searchPlaceholderKey="searchForClient"
          actionResolver={(rowData: IRowData) => {
            console.log(rowData);
            const actions: Action<ClientRepresentation>[] = [
              {
                title: t("edit"),
                onClick() {
                  const id = rowData.data.id;
                  navigate(id);
                },
              },
              {
                title: t("delete"),
                onClick() {
                  const id = rowData.data.id;
                  handleAreYouSureDelete(id);
                  toggleDeleteDialog();
                },
              },
            ];

            return actions;
          }}
          columns={[
            {
              name: "name",
              displayKey: "point-webhookName",
              transforms: [cellWidth(20)],
              cellRenderer: (row) => TextCell(row.name),
            },
            {
              name: "url",
              displayKey: "point-notificationUrl",
              transforms: [cellWidth(20)],
              cellRenderer: (row) => TextCell(row.url),
            },
            {
              name: "loginEvents",
              displayKey: "point-events",
              transforms: [cellWidth(20)],
              cellRenderer: (row) => TextCell(row.loginEvents),
            },
            {
              name: "adminEvents",
              displayKey: "point-adminEvents",
              transforms: [cellWidth(20)],
              cellRenderer: (row) => TextCell(row.adminEvents),
            },
          ]}
        />
      </PageSection>
    </>
  );
}
