import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  ValidatedOptions,
} from "@patternfly/react-core";
import { ViewHeader } from "../../../../components/view-header/ViewHeader";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormAccess } from "../../../../components/form/FormAccess";
import { KeycloakTextInput } from "../../../../components/keycloak-text-input/KeycloakTextInput";
import { adminClient } from "../../../../admin-client";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { useAlerts } from "../../../../components/alert/Alerts";
import { useNavigate } from "react-router-dom";
import { useRealm } from "../../../../context/realm-context/RealmContext";
import { toEmailThemeScope } from "../../../routes/emailThemeRoutes";

export type CreateTheme = {
  themeName: string;
};
export default function CreateEmailTheme() {
  const form = useForm<CreateTheme>({
    defaultValues: {
      themeName: "",
    },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = form;
  const { t } = useTranslation("point");
  const { addAlert, addError } = useAlerts();
  const navigate = useNavigate();
  const { realm } = useRealm();

  const onSubmit = async (data: CreateTheme) => {
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/create/theme/${data.themeName}`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
      });
      addAlert("Success ! New theme created", AlertVariant.success);
      const jsonsData = await response.json();
      navigate(
        toEmailThemeScope({
          realm,
          id: jsonsData.uuid,
        }),
      );
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  return (
    <>
      <ViewHeader titleKey={t("point-createEmailTheme")} />
      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormGroup
              label={t("point-createEmailThemeName")}
              isRequired
              helperTextInvalid={t("common:required")}
              validated={
                errors.themeName
                  ? ValidatedOptions.error
                  : ValidatedOptions.default
              }
            >
              <KeycloakTextInput
                isRequired
                id="theme_name"
                data-testid="name"
                validated={
                  errors.themeName
                    ? ValidatedOptions.error
                    : ValidatedOptions.default
                }
                {...register("themeName", { required: true })}
              />
              <ActionGroup>
                <Button
                  isDisabled={!isDirty}
                  variant="primary"
                  type="submit"
                  data-testid="createProvider"
                >
                  {t("common:add")}
                </Button>
              </ActionGroup>
            </FormGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
