import { Path } from "react-router-dom";
import { AppRouteObject } from "../../routes";
import { generateEncodedPath } from "../../utils/generateEncodedPath";
import CreateWebhook from "../pages/IntegrationPages/CreateWebhook";
import Integrations from "../pages/IntegrationPages/Integrations";
import EditWebhook from "../pages/IntegrationPages/EditWebhook";

const IntegrationRoute: AppRouteObject = {
  path: "/:realm/integrations",
  element: <Integrations />,
  breadcrumb: (t) => t("point-integrations"),
  handle: {
    access: ["anyone"],
  },
};

export const toIntegrationRoute = (params = {}): Partial<Path> => ({
  pathname: generateEncodedPath(IntegrationRoute.path, params),
});

const CreateIntegrationWebhookRoute: AppRouteObject = {
  path: "/:realm/integrations/create-webhook",
  element: <CreateWebhook />,
  breadcrumb: (t) => t("point-integrations"),
  handle: {
    access: ["anyone"],
  },
};

const EditIntegrationWebhookRoute: AppRouteObject = {
  path: "/:realm/integrations/:webhookId",
  element: <EditWebhook />,
  breadcrumb: (t) => t("point-integrations"),
  handle: {
    access: ["anyone"],
  },
};

export const integrationsRoutes = [
  IntegrationRoute,
  CreateIntegrationWebhookRoute,
  EditIntegrationWebhookRoute,
];
