import type WhoAmIRepresentation from "@keycloak/keycloak-admin-client/lib/defs/whoAmIRepresentation";
import type { AccessType } from "@keycloak/keycloak-admin-client/lib/defs/whoAmIRepresentation";
import { PropsWithChildren, useState } from "react";
import { createNamedContext, useRequiredContext } from "ui-shared";

import { adminClient } from "../../admin-client";
import environment from "../../environment";
import { DEFAULT_LOCALE, i18n } from "../../i18n/i18n";
import { useFetch } from "../../utils/useFetch";
import { useRealm } from "../realm-context/RealmContext";

export class WhoAmI {
  #me?: WhoAmIRepresentation;

  constructor(me?: WhoAmIRepresentation) {
    this.#me = me;
    if (this.#me?.locale) {
      i18n.changeLanguage(this.#me.locale, (error) => {
        if (error) {
          console.warn("Error(s) loading locale", this.#me?.locale, error);
        }
      });
    }
  }

  public getDisplayName(): string {
    if (this.#me === undefined) return "";

    return this.#me.displayName;
  }

  public getLocale() {
    return this.#me?.locale ?? DEFAULT_LOCALE;
  }

  public getRealm() {
    return this.#me?.realm ?? "";
  }

  public getUserId(): string {
    if (this.#me === undefined) return "";

    return this.#me.userId;
  }

  public canCreateRealm(): boolean {
    return !!this.#me?.createRealm;
  }

  public getRealmAccess(): Readonly<{
    [key: string]: ReadonlyArray<AccessType>;
  }> {
    if (this.#me === undefined) return {};

    return this.#me.realm_access;
  }
}

type WhoAmIProps = {
  refresh: () => void;
  whoAmI: WhoAmI;
};

export const WhoAmIContext = createNamedContext<WhoAmIProps | undefined>(
  "WhoAmIContext",
  undefined,
);

export const useWhoAmI = () => useRequiredContext(WhoAmIContext);

export const WhoAmIContextProvider = ({ children }: PropsWithChildren) => {
  const [whoAmI, setWhoAmI] = useState<WhoAmI>(new WhoAmI());
  const { realm } = useRealm();
  const [key, setKey] = useState(0);

  useFetch(
    () => {
      // const whoAmI: WhoAmIRepresentation = {
      //   userId: "0b22a85e-06d5-433e-9a67-c9068860f252",
      //   realm: "master",
      //   displayName: "admin",
      //   locale: "en",
      //   createRealm: true,
      //   realm_access: {
      //     master: [
      //       "view-realm",
      //       "view-identity-providers",
      //       "manage-identity-providers",
      //       "impersonation",
      //       "create-client",
      //       "manage-users",
      //       "query-realms",
      //       "view-authorization",
      //       "query-clients",
      //       "query-users",
      //       "manage-events",
      //       "manage-realm",
      //       "view-events",
      //       "view-users",
      //       "view-clients",
      //       "manage-authorization",
      //       "manage-clients",
      //       "query-groups",
      //     ],
      //   },
      // };
      // return new Promise((resolve) => resolve(whoAmI));
      return adminClient.whoAmI.find({
        realm: environment.loginRealm,
        currentRealm: realm!,
      });
    },
    (me) => {
      const whoAmI = new WhoAmI(me as WhoAmIRepresentation);

      setWhoAmI(whoAmI);
    },
    [key, realm],
  );

  return (
    <WhoAmIContext.Provider value={{ refresh: () => setKey(key + 1), whoAmI }}>
      {children}
    </WhoAmIContext.Provider>
  );
};
