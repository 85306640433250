import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SingleSelect from "../../../components/Select";
import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
} from "@patternfly/react-core";
import { useEffect, useState } from "react";
import { FormAccess } from "../../../../components/form/FormAccess";
import { KeycloakTextArea } from "../../../../components/keycloak-text-area/KeycloakTextArea";
import { ViewHeader } from "../../../../components/view-header/ViewHeader";
import { adminClient } from "../../../../admin-client";
import { getAuthorizationHeaders } from "../../../../utils/getAuthorizationHeaders";
import { useAlerts } from "../../../../components/alert/Alerts";

export type EditeEmailThemePage = {
  selectedEmailTemplate: string;
  emailHtml: string;
  emailText: string;
};
export default function EditEmailTheme() {
  const { addAlert, addError } = useAlerts();
  const { id } = useParams();
  const [templates, setTemplates] = useState<
    { label: string; value: string }[]
  >([]);
  const form = useForm<EditeEmailThemePage>({
    defaultValues: {
      selectedEmailTemplate: "",
      emailHtml: "",
      emailText: "",
    },
  });
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = form;
  const { t } = useTranslation("point");
  const selectedEmailTemplate = watch("selectedEmailTemplate");

  const getEmailTemplate = async () => {
    const url = `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/templates`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: getAuthorizationHeaders(await adminClient.getAccessToken()),
      });
      if (!response.ok) {
        throw new Error("Error fetching templates");
        return;
      }
      const jsonTemplatesData = await response.json();
      const templatesOptions = Object.keys(jsonTemplatesData).map((key) => ({
        label: jsonTemplatesData[key],
        value: key,
      }));

      setTemplates(templatesOptions);

      if (templatesOptions.length > 0) {
        setValue("selectedEmailTemplate", templatesOptions[0].value);
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  useEffect(() => {
    getEmailTemplate();
  }, []);

  useEffect(() => {
    if (selectedEmailTemplate) {
      loadTemplate(selectedEmailTemplate);
    }
  }, [selectedEmailTemplate]);

  const loadTemplate = async (selectedTemplateKey: any) => {
    const authHeaders = await getAuthorizationHeaders(
      await adminClient.getAccessToken(),
    );
    try {
      const [htmlResponse, textResponse] = await Promise.all([
        fetch(
          `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/templates/${id}/html/${selectedTemplateKey}`,
          {
            headers: authHeaders,
          },
        ),
        fetch(
          `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/templates/${id}/text/${selectedTemplateKey}`,
          {
            headers: authHeaders,
          },
        ),
      ]);
      if (!htmlResponse.ok) {
        throw new Error("Error fetching html template");
      }
      if (!textResponse.ok) {
        throw new Error("Error fetching text template");
      }
      const htmlData = await htmlResponse.text();
      const textData = await textResponse.text();
      setValue("emailHtml", htmlData);
      setValue("emailText", textData);
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  const onSubmit = async (
    data: Omit<EditeEmailThemePage, "selectedEmailTemplate">,
  ) => {
    const htmlFormData = new FormData();
    const textFormData = new FormData();
    htmlFormData.append("template", data.emailHtml);
    textFormData.append("template", data.emailText);

    try {
      if (selectedEmailTemplate) {
        const [htmlResponse, textResponse] = await Promise.all([
          fetch(
            `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/templates/${id}/html/${selectedEmailTemplate}`,
            {
              method: "PUT",
              body: htmlFormData,
              headers: getAuthorizationHeaders(
                await adminClient.getAccessToken(),
              ),
            },
          ),
          fetch(
            `${adminClient.baseUrl}/realms/${adminClient.realmName}/emails/templates/${id}/text/${selectedEmailTemplate}`,
            {
              method: "PUT",
              body: textFormData,
              headers: getAuthorizationHeaders(
                await adminClient.getAccessToken(),
              ),
            },
          ),
        ]);
        if (!htmlResponse.ok || !textResponse.ok) {
          throw new Error(
            `Failed to update templates: ${htmlResponse.statusText}`,
          );
        } else {
          addAlert("Success ! Templates Updated", AlertVariant.success);
        }
      }
    } catch (error) {
      addError(t("point-error"), error);
    }
  };

  return (
    <>
      <ViewHeader titleKey={t("point-editEmailTheme")} />
      <PageSection variant="light">
        <FormProvider {...form}>
          <FormAccess
            role="manage-identity-providers"
            isHorizontal
            onSubmit={handleSubmit(onSubmit)}
          >
            <SingleSelect
              label={t("point-selectEmail")}
              id="selectedEmailTemplate"
              options={templates}
              {...register("selectedEmailTemplate")}
            />
            <FormGroup label={t("point-emailHtml")}>
              <Controller
                name="emailHtml"
                control={control}
                render={({ field }) => (
                  <KeycloakTextArea
                    id="emailHtml"
                    rows={10}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup label={t("point-emailText")}>
              <Controller
                name="emailText"
                control={control}
                render={({ field }) => (
                  <KeycloakTextArea
                    id="emailText"
                    rows={5}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormGroup>
            <ActionGroup>
              <Button
                isDisabled={!isDirty}
                variant="primary"
                type="submit"
                data-testid="createProvider"
              >
                {t("common:save")}
              </Button>
            </ActionGroup>
          </FormAccess>
        </FormProvider>
      </PageSection>
    </>
  );
}
