import {
  Select,
  FormGroup,
  SelectVariant,
  SelectOption,
  SelectProps,
} from "@patternfly/react-core";

import { useState } from "react";

import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

type MultipleSelectProps<T extends FieldValues> = UseControllerProps<T> & {
  label?: string;
  options: {
    label: string;
    value: string;
  }[];
  onAfterItemChange?: (value: string) => void;
} & Partial<SelectProps>;

export default function MultiSelect<T extends FieldValues>({
  options,
  label,
  name,
  placeholder,
  ...controllerProps
}: MultipleSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    field: { onChange, value },
  } = useController({
    name,
    control: controllerProps.control,
    defaultValue: controllerProps.defaultValue,
    rules: controllerProps.rules,
    shouldUnregister: controllerProps.shouldUnregister,
  });

  return (
    <FormGroup label={label}>
      <Select
        maxHeight={375}
        toggleId={`${name}-multi`}
        variant={SelectVariant.typeaheadMulti}
        chipGroupProps={{
          numChips: 3,
        }}
        placeholderText={placeholder}
        menuAppendTo="parent"
        onToggle={(open) => setIsOpen(open)}
        isOpen={isOpen}
        selections={value as string[]}
        onSelect={(_, selectedValue) => {
          console.log({ value });
          const oldValue: string[] = value ? value : [];
          onChange(
            oldValue.find((item) => item === selectedValue)
              ? oldValue.filter((item) => item !== selectedValue)
              : [...oldValue, selectedValue],
          );
        }}
        onClear={(event) => {
          event.stopPropagation();
          onChange([]);
        }}
      >
        {options.map((option) => (
          <SelectOption key={option.label} value={option.value}>
            {option.label}
          </SelectOption>
        ))}
      </Select>
    </FormGroup>
  );
}
